<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" tab="用商订单">
        <list @setDirectTitle="handleInitial"></list>
      </a-tab-pane>
<!--      <a-tab-pane key="2" :tab="'直付公司订单' + (directTotal > -1 ? '(' + directTotal + '笔)' : '') ">-->
<!--      <a-tab-pane key="2" :tab="'直付公司订单' + '(' + directTotal + ' 笔)' ">-->
<!--        <direct-list  @setDirectTitle="setDirectTitle"></direct-list>-->
<!--      </a-tab-pane>-->
      <a-tab-pane key="3" :tab="'用付订单' + (userPayTotal > -1 ? '(' + userPayTotal + '笔)' : '') ">
        <user-pay-list  @setUserPayTitle="setUserPayTitle"></user-pay-list>
      </a-tab-pane>
      <a-tab-pane key="4" :tab="'商付订单' + (merchantPayTotal > -1 ? '(' + merchantPayTotal + '笔)' : '') ">
        <merchant-pay-list  @setMerchantPayTitle="setMerchantPayTitle"></merchant-pay-list>
      </a-tab-pane>
      <a-tab-pane key="5" :tab="'免审订单' + (noAuditTotal > -1 ? '(' + noAuditTotal + '笔)' : '') ">
        <no-audit-pay-list  @setNoAuditTitle="setNoAuditTitle"></no-audit-pay-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import List from "./List.vue"
import DirectList from "./direct/DirectList"
import UserPayList from "./direct/userPay/List.vue"
import MerchantPayList from "./direct/merchantPay/List.vue"
import NoAuditPayList from "./direct/noAuditPay/List.vue"
import {queryDirectOrder, queryNoAuditOrder} from "@/api/member-order";
import moment from "moment";

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MainList',
  components: {
    List,DirectList,UserPayList,MerchantPayList,NoAuditPayList
  },
  data() {
    return {
      directTotal: -1,
      userPayTotal:-1,
      merchantPayTotal:-1,
      noAuditTotal:-1,
    }
  },
  created() {
    // this.handleInitial();
  },
  methods: {
    setDirectTitle(value) {
      this.directTotal = value
    },
    setUserPayTitle(value) {
      this.userPayTotal = value
    },
    setMerchantPayTitle(value) {
      this.merchantPayTotal = value
    },
    setNoAuditTitle(value) {
      this.noAuditTotal = value
    },

    handleInitial(){

      let arr = this.getDataStartEnd(baseStart);
      let params1 = {};
      params1.start = arr[0];
      params1.end = arr[1];
      params1.directPay = '1';
      //用付订单
      queryDirectOrder(params1).then(datum => {
          this.setUserPayTitle(datum.total );
      })

      //商付订单
      let params2 = {};
      params2.start = arr[0];
      params2.end = arr[1];
      params2.directPay = '2';
      queryDirectOrder(params2).then(datum => {
        this.setMerchantPayTitle(datum.total );
      })
      //免审
      // let params3 = {};
      // params3.start = arr[0];
      // params3.end = arr[1];
      // params3.directPay = '3';
      // queryDirectOrder(params3).then(datum => {
      //   this.setNoAuditTitle(datum.total );
      // })

      let params3 = {};
      params3.start = arr[0];
      params3.end = arr[1];
      queryNoAuditOrder(params3).then(datum => {
        this.setNoAuditTitle(datum.total);
      })
    },
    getDataStartEnd(tempMonth){
      let start = "";
      let end = "";

      let monthYear = tempMonth.split("-");
      let year = monthYear[0];
      let month = monthYear[1];
      end = this.getMonthFinalDay(year,month);
      start = year + "-" + month + "-" + "01";

      end = end + " 23:59";
      start = start + " 00:00";

      let array = [];
      array.push(start);
      array.push(end);
      return array;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },

  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
