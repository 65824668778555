<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本月订单实收：</div>
          <div class="card_content color-red">{{dashboard.monthOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.monthAmount}}
<!--            <span style="padding-left: 20px;">￥{{dashboard.orderAmount1}}</span>-->
          </div>
        </a-card>
      </a-col>
<!--      <a-col :sm='12' :md='6' :xl='4' :style="{ marginBottom: '24px' }">-->
<!--        <a-card class="order_count" @dblclick="toTableList">-->
<!--          <div class="card_content" v-for="i in 6" :key="i">{{i}}：<span class="color-red">{{dashboard["orderCount" + i]}}</span></div>-->
<!--          <div class="card_content color-red">￥{{dashboard.orderAmount}}</div>-->
<!--          <div class="card_content color-red">￥{{dashboard.originAmount1}}</div>-->
<!--        </a-card>-->
<!--      </a-col>-->
      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card class="order_count" >
          <div class="card_title">
            <a-row :style="{ marginBottom: '10px',marginTop:'9px' }">
              <a-col :span="12">兑换订单笔数：<span class="color-red">{{dashboard.exchangeOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.exchangeOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12">现金订单笔数：<span class="color-red">{{dashboard.cashOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.cashOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12">积分订单笔数：<span class="color-red">{{dashboard.integralOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.integralOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '9px' }">
              <a-col :span="12">混用订单笔数：<span class="color-red">{{dashboard.mixOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.mixOrderAmount}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
      <a-col :sm='12' :md='6' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">待收货款订单：</div>
          <div class="card_content color-red">{{dashboard.waitOPaidOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.waitOPaidAmount}}</div>
        </a-card>
      </a-col>
    </a-row>

    <a-form-model ref='form2' :model='deliver2.form' layout='inline'>
      <a-form-model-item prop='start'>
        <a-month-picker
          v-model="dateArr2"
          :format="formatMonthType"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate2"
          @change="dateChange2"
        />
      </a-form-model-item>
      <a-form-model-item prop='addressUserName'>
        <a-input v-model='deliver2.form.addressUserName' placeholder="购买人" />
      </a-form-model-item>
      <a-form-model-item prop='addressUserMobile'>
        <a-input v-model='deliver2.form.addressUserMobile' placeholder="手机号" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit2'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset2'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table2.refresh(true)'>刷新</a-button>
      </div>

      <a-list style="margin-top: 20px;margin-bottom: 20px;">
        <span style="margin-right: 60px;" >订单笔数：{{ this.noAuditDashboard.total }}</span>
        <span style="margin-right: 60px;" >应付金额：{{ this.noAuditDashboard.ext1 }}</span>
        <span style="margin-right: 60px;" >使用折扣：{{ this.noAuditDashboard.ext2 }}</span>
        <span style="margin-right: 60px;" >实付金额：{{ this.noAuditDashboard.ext3 }}</span>
      </a-list>

          <s-table id="table2" ref='table2' rowKey='id' size='default' :columns='columns2' :scroll='{ x: 1500}' :data='loadData2'>
            <span slot='yingFU' slot-scope='text, record'>
              <template>
                  {{record.merchantMember?record.originalMerchantAmount:record.amount}}
              </template>
            </span>

            <span slot='discount' slot-scope='text, record'>
              <template>
                  {{record.merchantMember?record.merchantDiscount:record.memberDiscount}}
              </template>
            </span>

            <span slot='shiFU' slot-scope='text, record'>
              <template>
                  {{record.merchantMember?record.merchantAmount:record.consumeAmount}}
              </template>
            </span>

            <span slot='payMethod' slot-scope='text, record'>
              <template>
                  {{record.merchantMember?record.merchantPaymentMethodName:record.paymentMethodName}}
              </template>
            </span>
          </s-table>
    </div>


  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryProductions } from '@/api/production'
import { queryMealCategories, queryMeal } from '@/api/meal'
import {
  addMemberOrder,
  getMerchantOrderData,
  getMobileMember,
  getMobileMerchant,
  putBindMerchant,
  queryRewardList, queryDirectOrder, queryNoAuditOrder
} from '@/api/member-order'
import { getMerchantInfo, putMobileMember } from '@/api/member'
import region from '@/config/region'
import moment from 'moment'


const columns2 = [
  {
    width: 80,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 90,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 90,
    title: '支付时间',
    dataIndex: 'merchantActualPaymentTime',
    scopedSlots: { customRender: 'merchantActualPaymentTime' }
  },
  {
    width: 80,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 100,
    title: '购买手机号码',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 70,
    title: '应付金额',
    dataIndex: 'yingFU',
    scopedSlots: { customRender: 'yingFU' }
  },
  {
    width: 70,
    title: '使用折扣',
    dataIndex: 'discount',
    scopedSlots: { customRender: 'discount' }
  },
  {
    width: 70,
    title: '实付金额',
    dataIndex: 'shiFU',
    scopedSlots: { customRender: 'shiFU' }
  },
  {
    width: 70,
    title: '支付方式',
    dataIndex: 'payMethod',
    scopedSlots: { customRender: 'payMethod' }
  },
  {
    width: 70,
    title: '品牌公司',
    dataIndex: 'memberAreaName',
    scopedSlots: { customRender: 'memberAreaName' },
  }
]


const formatType = 'YYYY-MM-DD';
const formatMonthType = 'YYYY-MM';
const createFormatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatMonthType);
const baseEnd =  moment().endOf('day').startOf('minute').format(createFormatType);
const month = moment().format(formatMonthType);


export default {
  name: 'OrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      columns2,
      wasCheck:false,
      // 筛选表单
      formatType,
      createFormatType,
      formatMonthType,
      dateArr2: month,
      month,
      form: {
        keyword: "",
        // start: baseStart,
        // end: baseEnd,
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      noAuditDashboard:{},
      loadData2: parameter => {
        let ss = this.dateArr2;
        let tempMonth = this.deliver2.form.start;
        let arr = this.getDataStartEnd(tempMonth);
        let param = Object.assign(parameter, this.queryParam,{start:arr[0],end:arr[1]});
        param.addressUserName=this.deliver2.form.addressUserName;
        param.addressUserMobile=this.deliver2.form.addressUserMobile;
        // debugger;
        return queryNoAuditOrder(param).then(datum => {

          this.noAuditDashboard = datum;
          return datum
        })
      },
      checkPoints:false,
      piLiangDisable:true,
      // prodList: [],
      mealTypeList: [],
      mealList: [],
      rewardList:[],
      memberInfo: {},

      region: region,
      deliver2: {
        visible: false,loading: false,
        form: {
          start: baseStart,
        },
        rules: {
        }, 
        bankAccountList: []
      },
    }
  },
  // activated(){
  //   this.getOrderData();
  //   // this.$refs.table.refresh(true)
  // },
  created() {
    this.getOrderData();
    // this.$refs.table.refresh(true)
  },
  methods: {
    moment,
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    disabledDate2(current) {
      return current && current > moment().endOf('month');
    },
    dateChange2() {
      let start  =  this.dateArr2 ? moment(this.dateArr2).format(this.formatMonthType) : "";
      debugger
      this.deliver2.form.start = start;
    },
    handleSubmit2() {
      this.$refs.table2.refresh(true)
    },
    handleReset2() {
      this.dateArr2 = month;
      this.deliver2.form.start = month;
      this.$refs.form2.resetFields()
      this.$refs.table2.refresh(true)
    },

    getDataStartEnd(tempMonth){
      let start = "";
      let end = "";

      let monthYear = tempMonth.split("-");
      let year = monthYear[0];
      let month = monthYear[1];
      end = this.getMonthFinalDay(year,month);
      start = year + "-" + month + "-" + "01";

      end = end + " 23:59";
      start = start + " 00:00";

      let array = [];
      array.push(start);
      array.push(end);
      return array;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getStatusStr(record){
        let memberStatus = record.status;
        let merchantOrderStatus = record.merchantOrderStatus;
        // debugger;
        if(memberStatus == 'paying'||merchantOrderStatus == '1'){
          return true;
        }else{
          return false;
        }
    },
    getOrderData() {
      getMerchantOrderData(Object.assign({}, this.deliver2.form)).then(result => {
        this.dashboard = result;
        this.loading = false;
      })

      let tempMonth = moment().format(formatMonthType);
      let arr = this.getDataStartEnd(tempMonth);
      let param = Object.assign({start:arr[0],end:arr[1]});
      queryNoAuditOrder(param).then(datum => {

        this.noAuditTotal = datum.total;
      })
    },

  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>
