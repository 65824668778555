<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本月订单实收：</div>
          <div class="card_content color-red">{{dashboard.monthOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.monthAmount}}
<!--            <span style="padding-left: 20px;">￥{{dashboard.orderAmount1}}</span>-->
          </div>
        </a-card>
      </a-col>
<!--      <a-col :sm='12' :md='6' :xl='4' :style="{ marginBottom: '24px' }">-->
<!--        <a-card class="order_count" @dblclick="toTableList">-->
<!--          <div class="card_content" v-for="i in 6" :key="i">{{i}}：<span class="color-red">{{dashboard["orderCount" + i]}}</span></div>-->
<!--          <div class="card_content color-red">￥{{dashboard.orderAmount}}</div>-->
<!--          <div class="card_content color-red">￥{{dashboard.originAmount1}}</div>-->
<!--        </a-card>-->
<!--      </a-col>-->
      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card class="order_count" >
          <div class="card_title">
            <a-row :style="{ marginBottom: '10px',marginTop:'9px' }">
              <a-col :span="12">兑换订单笔数：<span class="color-red">{{dashboard.exchangeOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.exchangeOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12">现金订单笔数：<span class="color-red">{{dashboard.cashOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.cashOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12">积分订单笔数：<span class="color-red">{{dashboard.integralOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.integralOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '9px' }">
              <a-col :span="12">混用订单笔数：<span class="color-red">{{dashboard.mixOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.mixOrderAmount}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
      <a-col :sm='12' :md='6' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">待收货款订单：</div>
          <div class="card_content color-red">{{dashboard.waitOPaidOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.waitOPaidAmount}}</div>
        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='id'>
        <a-input v-model='form.id' style="width: 200px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='keyword' >
        <a-input v-model='form.keyword' style="width: 200px" placeholder="购买人姓名" />
      </a-form-model-item>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 200px" placeholder="负责人" />
      </a-form-model-item>
        <a-form-model-item prop='directStatus'>
          <a-select v-model="form.directStatus" placeholder="状态" style="width: 150px">
            <a-select-option value="">
              状态
            </a-select-option>
            <a-select-option :value="1">
             待确认
            </a-select-option>
            <a-select-option :value="2">
              已确认
            </a-select-option>
          </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[createFormatType, createFormatType]"
          :show-time="{
            hideDisabledOptions: true,
            defaultValue: [moment('00:00', 'HH:mm:ss'), moment('23:59', 'HH:mm:ss')],
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
<!--        <a-button type='primary' icon='plus' @click='initialDeliver1'>手动录单</a-button>-->
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>

         <span slot='status' slot-scope='text,record'>
          <template>
              {{getStatusStr(record)?'待确定':'已确认'}}
          </template>
        </span>

        <span slot='status' slot-scope='text,record'>
          <template>
              {{getStatusStr(record)?'待确定':'已确认'}}
          </template>
        </span>

        <span slot='consumeAmount' slot-scope='text,record'>
          <template v-if="record.merchantMember">
              {{record.merchantAmount}}
          </template>
          <template v-else>
               {{text}}
          </template>
        </span>

        <span slot='paymentMethodType' slot-scope='text,record'>
          <template>
              {{getPayMethodTypeName(record)}}
          </template>
        </span>

        <span slot='action' slot-scope='text,record'>
          <template>
              <a v-if="getStatusStr(record)" @click="handleEdit(record)">确认收款</a>
              <a v-else @click="viewMore(record)">查看详情</a>
          </template>
        </span>
      </s-table>
    </div>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryProductions } from '@/api/production'
import { queryMealCategories, queryMeal } from '@/api/meal'
import {
  addMemberOrder,
  getMerchantOrderData,
  getMobileMember,
  getMobileMerchant,
  putBindMerchant,
  queryRewardList, queryDirectOrder
} from '@/api/member-order'
import { getMerchantInfo, putMobileMember } from '@/api/member'
import region from '@/config/region'
import moment from 'moment'


const columns = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '订单金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  // {
  //   width: 80,
  //   title: '支付类型',
  //   dataIndex: 'paymentMethod',
  //   scopedSlots: { customRender: 'paymentMethod' }
  // },
  {
    width: 80,
    title: '支付方式',
    dataIndex: 'paymentMethod',
    scopedSlots: { customRender: 'paymentMethodType' }
  },
    {
    width: 80,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 80,
    title: '购买人手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '商户负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' },
  },
  {
    width: 100,
    title: '实付金额',
    dataIndex: 'consumeAmount',
    scopedSlots: { customRender: 'consumeAmount' },
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    width: 80,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]


const formatType = 'YYYY-MM-DD';
const formatMonthType = 'YYYY-MM';
const createFormatType = 'YYYY-MM-DD HH:mm';
// const baseStart = moment().format(formatType);
const baseStart = moment().startOf('month').format(createFormatType);
const baseEnd =  moment().endOf('day').startOf('minute').format(createFormatType);



export default {
  name: 'OrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      wasCheck:false,
      // 筛选表单
      formatType,
      createFormatType,
      formatMonthType,
      dateArr: [baseStart, baseEnd],
      dateArr2: baseStart,
      form: {
        keyword: "",
        // start: baseStart,
        // end: baseEnd,
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        // let tempMonth = this.form.start;

        let arr = this.getDataStartEnd(baseStart);
        // this.form.start = arr[0];
        // this.form.end = arr[1];
        this.form.directPay = '2';
        let param = Object.assign(parameter, this.queryParam, this.form);

        return queryDirectOrder(param)
          .then(datum => {
            this.$emit("setMerchantPayTitle",datum.total);//更新直付数量
            return datum
          })
      },
      checkPoints:false,
      piLiangDisable:true,
      // prodList: [],
      mealTypeList: [],
      mealList: [],
      rewardList:[],
      memberInfo: {},
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          mobile: [
            { required: true, message: '请填写下单账号（手机）', trigger: 'change' },
            // { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],
          nickname: [{ required: true, message: '下单人姓名', trigger: 'change' }],
          spreaderMobile: [
            { required: true, message: '请填写推荐人手机号', trigger: 'change' },
            // { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],         
          createTime: [{ required: true, message: '请选择', trigger: 'change' }],
          operator: [{ required: true, message: '请选择套餐类型', trigger: 'change' }],
          production: [{ required: true, message: '请选择商品', trigger: 'change' }],
          count: [
            { required: true, message: '请填写商品数量', trigger: 'change' },
            { type: 'integer', message: '商品数量填写整数', trigger: 'change' }
          ],
          amount: [{ required: true, message: '请填写订单金额', trigger: 'change' }],
          isUseMing:[{ required: false, message: '请填写订单金额', trigger: 'click'}],
          piLiang:[],
          bussType: [{ required: true, message: '请选择发货方式', trigger: 'change' }],
          name: [{ required: true, message: '请填写收货人', trigger: 'change' }],
          addressMobile: [{ required: true, message: '请填写收货人手机号', trigger: 'change' }],
          region: [{ required: true, message: '请选择地区', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          drawName: [{ required: true, message: '请填写提货人', trigger: 'change' }],
          drawMobile: [{ required: true, message: '请填写提货人手机号', trigger: 'change' }]
        },
      },
      region: region,
      deliver2: {
        visible: false,loading: false,
        form: {
          start: baseStart,
        },
        rules: {
        }, 
        bankAccountList: []
      },
    }
  },
  // activated(){
  //   this.getOrderData();
  //   // this.$refs.table.refresh(true)
  // },
  created() {
    this.getOrderData();
    // this.$refs.table.refresh(true)
  },
  methods: {
    moment,
    toTableList() {
      this.$router.push("/order/table/list");
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.createFormatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.createFormatType) : "";
    },
    getMerchantInfo() {
      getMerchantInfo({ mobile: this.deliver1.form.mobile }).then(result => {
        this.$set(this.deliver1.form, "masterName", result.merchantName);
      })
    },
    getMobileMember() {
      getMobileMember({phone: this.deliver1.form.mobile}).then(result => {
        this.$set(this.deliver1.form, "member", result.id);
        this.$set(this.deliver1.form, "nickname", result.nickname);
        this.$set(this.deliver1.form, "oldSpreaderMobile", result.spreaderMobile);
        this.$set(this.deliver1.form, "spreaderMobile", result.spreaderMobile);
        this.$set(this.deliver1.form, "spreaderName", result.spreaderName);
      })
    },
    getSpreaderMember() {
      getMobileMerchant({mobile: this.deliver1.form.spreaderMobile}).then(result => {
        this.$set(this.deliver1.form, "masterName", result.masterName);
        this.$set(this.deliver1.form, "spreaderName", result.merchantName);
      })
    },
    onChangeSpreaderMobile() {
      if (this.deliver1.form.spreaderMobile.length >= 9) {
        this.getSpreaderMember();
      } else {
        this.$set(this.deliver1.form, "masterName", "");
        this.$set(this.deliver1.form, "spreaderName", "");
      }
    },
    onChangeMobile() {
      delete this.deliver1.mobileHelp;
      delete this.deliver1.mobileStatus;

      if (this.deliver1.form.mobile.length >= 9) {
        this.getMerchantInfo();
        this.getMobileMember();
        // debugger
        if (this.deliver1.form.orderType == 1) {
          // this.handleSearch1()
          this.onChangeCount()
        }
        // else {
        //   this.handleSearch2()
        //   this.handleSearch3()
        //   this.onChangeCount()
        // }
      } else {
        this.$set(this.deliver1.form, "masterName", "");
        this.$set(this.deliver1.form, "oldSpreaderMobile", "");
        this.$set(this.deliver1.form, "spreaderMobile", "");
        this.$set(this.deliver1.form, "spreaderName", "");
      }
    },

    onChangeCount(value) {
      // debugger;
      // if(value&&value>=0){
      //重置价格
      this.deliver1.form.amount = 0;
      this.deliver1.form.count = 0;

      let productList = this.deliver1.form.prodList;
        let prod = [];
        for(let i in productList){
          let p = productList[i];
          p.showPrice = p.count * p.price;
          if(p.count>0){
            this.deliver1.form.count = this.deliver1.form.count +  p.count;
            this.deliver1.form.amount = this.deliver1.form.amount +  p.showPrice;
            let pro={production:0,quantity:0};
            pro.production = p.id;
            pro.quantity = p.count;
            prod.push(pro);
          }
        }
        //设置商品id


        if (this.deliver1.form.count>0) {
          //显示奖励列表
          let spreaderMobile = this.deliver1.form.spreaderMobile;
          if(!spreaderMobile){
            this.$message.error('请先绑定推荐人！');
            return;
          }
          if(this.deliver1.form.mobile){
            // debugger;
            this.$set(this.deliver1.form, "productionList", prod);
            this.showRewardList();
          }else{
            this.$message.info('请输入手机号码，获取奖励详情')
          }
        }
      // }

    },
    showRewardList(){
      //输入了电话号码才能访问奖励列表

        let mobile = this.deliver1.form.mobile;
        let amount = this.deliver1.form.amount;
        let param = Object.assign({mobile:mobile,amount:amount});
        return queryRewardList(param).then(datum => {
          // debugger;
          this.rewardList = datum;
        })
      // }

    },
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },

    handleSearch1(keywords) {
      let _this = this;
      let prams = { current: 1, size: 1000,categoryName:'全部商品', status: 'online', mobile: this.deliver1.form.mobile };
      // debugger;
      queryProductions(prams).then(result => {
        // _this.deliver1.form.prodList = result.records;
        let pl = result.records;
        for(let p in pl){
          pl[p].count = 0;
          pl[p].showPrice = 0;
        }
        _this.deliver1.form = {
          createTime: moment().format(createFormatType),
          bussType: "deliver",
          paymentMethod: "trans",
          orderType: 1,
          prodList:result.records,
        }
        _this.deliver1.loading = false
        _this.deliver1.visible = true
        this.deliver1.form.count =0;
        this.deliver1.form.amount = 0;
      })
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "operator", e)
      this.$set(this.deliver1.form, "production", "")
    },
    handleSearch2(keywords) {
      queryMealCategories({ current: 1, size: 1000, isEmployee: 1 }).then(result => {
        this.mealTypeList = result.records
      })
    },
    handleChange3(e) {
      this.$set(this.deliver1.form, "production", e)
      this.onChangeCount()
    },
    handleSearch3(keywords) {
      queryMeal({ type: 10, current: 1, size: 1000, status: 'online', mobile: this.deliver1.form.mobile }).then(result => {
        this.mealList = result.records
      })
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;

      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true);

    },
    handleSignSubmit() {
      // if (!/^1[3456789]\d{9}$/.test(this.deliver1.form.mobile)) {
      //   this.$message.warning('请输入正确手机号')
      //   return;
      // }
      putMobileMember({mobile: this.deliver1.form.mobile}).then(_result => {
        this.$message.success('注册成功')
        this.$set(this.deliver1, "mobileHelp", "注册成功")
        this.$set(this.deliver1, "mobileStatus", "error")
        this.getMobileMember();
        if (this.deliver1.form.orderType == 1) {
          this.handleSearch1()
          this.onChangeCount(this.deliver1.form.count)
        } else {
          this.handleSearch2()
          this.handleSearch3()
          this.onChangeCount()
        }
      }).catch((e) => {
        this.$set(this.deliver1, "mobileHelp", e.data.errorMsg)
        this.$set(this.deliver1, "mobileStatus", "error")
      })
    },
    handleSpreaderSubmit() {
      // if (!/^1[3456789]\d{9}$/.test(this.deliver1.form.spreaderMobile)) {
      //   this.$message.warning('请输入正确手机号')
      //   return;
      // }
      putBindMerchant({id: this.deliver1.form.member, mobile: this.deliver1.form.spreaderMobile}).then(_result => {
        this.$message.success('绑定成功')
      })
    },
    getOrderData() {
      getMerchantOrderData(Object.assign({}, this.form)).then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    handleEdit(record) {
      // debugger;
      this.$router.push({ path: '/order/direct/edit', query: { id: record.id,isView:false,orderType:'2'} })
      // this.$router.push({ path: '/order/edit/'+record.id })
    },
    viewMore(record){
      this.$router.push({ path: '/order/direct/edit', query: { id: record.id,isView:true,orderType:'2' } })
    },
    initialDeliver1() {
      this.handleSearch1()
    },
    handleDeliver1() {
      // console.log("提交了！！！"+this.deliver1.form.piLiang);

      this.$refs.form1.validate((result) => {
        if (result) {
          // debugger;
          if (this.deliver1.form.oldSpreaderMobile != this.deliver1.form.spreaderMobile) {
            this.deliver1.loading = true;
            putBindMerchant({id: this.deliver1.form.member, mobile: this.deliver1.form.spreaderMobile}).then(_result => {
              this.$message.success('绑定成功')
              this.deliver1.loading = false;
              this.addOrder();
            }).catch(() => {
              this.deliver1.loading = false;
            })
          } else {
            this.addOrder();
          }
        }
      })
    },

    addOrder() {
      if (this.deliver1.form.region && this.deliver1.form.region.length > 0) {
        this.deliver1.form.regionOneCode = this.deliver1.form.region[0].adcode;
        this.deliver1.form.regionOneName = this.deliver1.form.region[0].name;
        this.deliver1.form.regionTwoCode = this.deliver1.form.region[1].adcode;
        this.deliver1.form.regionTwoName = this.deliver1.form.region[1].name;
        this.deliver1.form.regionThreeCode = this.deliver1.form.region[2].adcode;
        this.deliver1.form.regionThreeName = this.deliver1.form.region[2].name;

      }
      //不使用名额，1表示不使用，0表示使用
      if(this.deliver1.form.useQuota){
        this.deliver1.form.useQuota = 1;
      }else{
        this.deliver1.form.useQuota = 0;
      }

      if(this.deliver1.form.piLiang){
        this.deliver1.form.batchSelect = 1;
      }else{
        this.deliver1.form.batchSelect = 0;
      }

      //系统积分赠送
      let form = this.deliver1.form;

      if(form.systemPoints){
        this.deliver1.form.rewardList = this.rewardList;
      }

      const parameter = JSON.parse(JSON.stringify(this.deliver1.form));
      delete parameter.region;
      this.deliver1.loading = true;

      if (parameter.orderType === 1) {

        addMemberOrder(parameter).then(_result => {
          this.$message.success('操作成功')
          this.$refs.table.refresh(true)
          this.deliver1.visible = false
          this.deliver1.loading = false;
        }).catch(() => {
          this.deliver1.loading = false;
        })

      }
      // else {
      //   addPackageOrder(parameter).then(_result => {
      //     this.$message.success('操作成功')
      //     this.$refs.table.refresh(true)
      //     this.deliver1.visible = false
      //     this.deliver1.loading = false;
      //   }).catch(() => {
      //     this.deliver1.loading = false;
      //   })
      // }
    },
    getDataStartEnd(tempMonth){
      let start = "";
      let end = "";

      let monthYear = tempMonth.split("-");
      let year = monthYear[0];
      let month = monthYear[1];
      end = this.getMonthFinalDay(year,month);
      start = year + "-" + month + "-" + "01";

      end = end + " 23:59";
      start = start + " 00:00";

      let array = [];
      array.push(start);
      array.push(end);
      return array;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getStatusStr(record){
        let memberStatus = record.status;
        let merchantOrderStatus = record.merchantOrderStatus;
        // debugger;
        if(memberStatus == 'paying'||merchantOrderStatus == '1'){
          return true;
        }else{
          return false;
        }
    },
    getPayMethodName(record){
      let merchantMember = record.merchantMember;
      let shiFu = 0;
      let discount = 0;
      if(merchantMember){
        //商户
        shiFu = record.merchantAmount;
        discount = record.merchantDiscount;
      }else{
        //用户
        shiFu = record.consumeAmount;
        discount = record.memberDiscount;
      }

      if(shiFu>0 && discount>0){
        //混合
        return  '混合';
      }else if( discount==0){
        return  '现金';
      }else if(shiFu==0){
        return  '积分';
      }else{
        return  '其他';
      }

    },
    getPayMethodTypeName(record){
      let merchantMember = record.merchantMember;
      if(merchantMember){
        //商户
        return  record.merchantPaymentMethodName;
      }else{
        //用户
        return  record.paymentMethodName;
      }


    }

  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>
